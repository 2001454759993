import { get, isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";

const useRedirect = (shouldRedirect, to) => {
  const router = useRouter();

  if (!isEmpty(shouldRedirect)) {
    router.push(to);
  }
};

const useRedirectDeActiveAccounts = (configuration) => {
  const router = useRouter();
  const isDeActive = get(configuration, "account.state") === "deactive";
  const pathName = router.pathname;

  useEffect(() => {
    const path = "/settings/subscriptions";

    if (isDeActive && pathName !== path) {
      router.push(`${path}`);
    }
  }, [isDeActive, pathName]);
};

export default useRedirect;
export { useRedirectDeActiveAccounts };
